import { Resource as ResourceType } from '../types';

export const client: ResourceType = {
  name: 'clients',
  label: 'resource.clients.label',
  hideResource: false,
  attributes: [
    {
      key: 'id',
      label: 'resource.users.field.id.label',
      type: 'text',
    },
    {
      key: 'company_id',
      label: 'resource.users.field.company_id.label',
      type: 'reference',
      reference: 'companies',
    },
    {
      key: 'first_name',
      label: 'resource.users.field.first_name.label',
      type: 'text',
    },
    {
      key: 'last_name',
      label: 'resource.users.field.last_name.label',
      type: 'text',
    },
    {
      key: 'company_name',
      label: 'resource.users.field.company_name.label',
      type: 'text',
    },
    {
      key: 'email',
      label: 'resource.users.field.email.label',
      type: 'email',
    },
    {
      key: 'phone',
      label: 'resource.users.field.phone.label',
      type: 'phone',
    },
    {
      key: 'company_kind',
      label: 'resource.companies.field.kind.label',
      type: 'select',
      choices: [
        {
          id: 'unknown',
          name: 'resource.companies.field.kind.choices.unknown.label',
        },
        {
          id: 'esn',
          name: 'resource.companies.field.kind.choices.esn.label',
        },
        {
          id: 'freelance',
          name: 'resource.companies.field.kind.choices.freelance.label',
        },
        {
          id: 'agency',
          name: 'resource.companies.field.kind.choices.agency.label',
        },
        {
          id: 'startup',
          name: 'resource.companies.field.kind.choices.startup.label',
        },
        {
          id: 'consulting',
          name: 'resource.companies.field.kind.choices.consulting.label',
        },
        {
          id: 'smes',
          name: 'resource.companies.field.kind.choices.smes.label',
        },
        {
          id: 'major_company',
          name: 'resource.companies.field.kind.choices.major_company.label',
        },
      ],
    },
    {
      key: 'job_name',
      label: 'resource.users.field.job_name.label',
      type: 'text',
    },
    {
      key: 'anonymized',
      label: 'resource.users.field.anonymized.label',
      type: 'boolean',
    },
    {
      key: 'blocked',
      label: 'resource.users.field.blocked.label',
      type: 'boolean',
    },
    {
      key: 'is_registered',
      label: 'resource.users.field.is_registered.label',
      type: 'boolean',
    },
    {
      key: 'cgu_accepted_at',
      label: 'resource.users.field.cgu_accepted_at.label',
      type: 'date',
    },
    {
      key: 'created_at',
      label: 'resource.users.field.created_at.label',
      type: 'date',
    },
    {
      key: 'administrator',
      label: 'resource.users.field.administrator.label',
      type: 'boolean',
    },
  ],
  pages: {
    list: {
      hasEditButton: true,
      hasCreateButton: true,
      editAndCreateBasePath: '/users',
      exportKindType: 'client',
      search: {
        enabled: true,
        label: 'filter.search.label',
      },
      extensions: [],
      bulkActionButtons: [
        {
          name: 'validate',
          type: 'update',
          label: 'resource.users.bulk_action_buttons.validate.label',
          values: {
            validated: true,
          },
        },
        {
          name: 'unvalidate',
          type: 'update',
          label: 'resource.users.bulk_action_buttons.unvalidate.label',
          values: {
            validated: false,
          },
        },
        {
          name: 'block',
          type: 'update',
          label: 'resource.users.bulk_action_buttons.block.label',
          icon: 'Lock',
          values: {
            blocked: true,
          },
        },
        {
          name: 'unblock',
          type: 'update',
          label: 'resource.users.bulk_action_buttons.unblock.label',
          icon: 'LockOpen',
          values: {
            blocked: false,
          },
        },
        {
          name: 'anonymize',
          type: 'update',
          label: 'resource.users.bulk_action_buttons.anonymize.label',
          values: {},
          extraUrl: '/anonymize',
          icon: 'VisibilityOff',
          // Following settings are only useful for update with confirmation
          withConfirmation: true,
          confirmation: {
            content: 'modal.bulk_anonymize.content',
          },
        },
      ],
      filters: [
        {
          source: 'blocked',
        },
        {
          source: 'administrator',
        },
        {
          source: 'company_id',
          type: 'reference',
          child: {
            type: 'autocomplete',
            // Options to pass to ReferenceInput
            options: {
              perPage: 10,
              allowEmpty: false,
            },
          },
        },
        {
          source: 'company_kind',
        },
        {
          source: 'anonymized',
        },
      ],
      fields: [
        {
          source: 'first_name',
          sortable: true,
        },
        {
          source: 'last_name',
          sortable: true,
        },
        {
          source: 'email',
          sortable: true,
        },
        {
          source: 'phone',
          sortable: false,
        },
        {
          source: 'company_kind',
        },
        {
          source: 'job_name',
        },
        {
          source: 'blocked',
          sortable: false,
        },
        {
          source: 'is_registered',
        },
        {
          source: 'cgu_accepted_at',
          sortable: false,
        },
        {
          source: 'created_at',
        },
      ],
    },
    edit: {
      left: {
        tabs: [],
      },
      right: [],
    },
  },
};
