import { Config as ConfigType } from './types';
import { user } from './taho/user';
import { expert } from './taho/expert';
import { client } from './taho/client';
import { company } from './taho/company';
import { mission } from './taho/mission';
import { invitation } from './taho/invitation';
import { candidate } from './s30/candidate';
import { candidacy } from './taho/candidacy';
import { proposal } from './taho/proposal';
import { quotation } from './taho/quotation';
import { charge } from './taho/charge';
import { milestone } from './taho/milestone';
import { contract } from './taho/contract';
import { contractMilestone } from './taho/contractMilestone';
import { invoice } from './taho/invoice';
import { recommendation } from './taho/recommendation';
import { legalInformation } from './taho/legalInformation';
import { cgu } from './taho/cgu';
import { match } from './taho/match';
import { document } from './taho/document';
import { subCategory } from './taho/subCategory';
import { documentFile } from './taho/documentFile';
import { documentsCompanies } from './taho/documentsCompanies';
import { tag } from './taho/tag';

export const config: ConfigType = {
  login: 'password',
  hash: '',
  locale: 'fr',
  name: 'taho',
  dashboard: true,
  theme: {
    primary: '#ee706a',
    secondary: '#ee706a',
    info: '#fdf0f0',
  },
  defaultCountry: 'ma',
  preferredCountries: ['ma', 'fr'],
  resources: [
    expert,
    client,
    user,
    subCategory,
    company,
    mission,
    invitation,
    candidate,
    candidacy,
    proposal,
    quotation,
    charge,
    milestone,
    contract,
    contractMilestone,
    invoice,
    recommendation,
    legalInformation,
    document,
    documentFile,
    documentsCompanies,
    cgu,
    match,
    tag,
  ],
};

export default config;
